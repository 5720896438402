import React from "react"
import Helmet from "react-helmet";

import Header from "./header"

import * as styles from "./layout.module.scss"

const Layout = ({ children }) => {
    return (
        <>
            <Helmet>
                <html lang="en" />
                <title>Burton Peake</title>
                <meta name="description" content="Software development and data science consultancy in the Oxfordshire Cotswolds" />
                <meta name="keywords" content="data,data science,full-stack,developer,python,javascript" />
                <meta name="author" content="Zoe Burton and Michael Peake" />
            </Helmet>
            <Header />
            <main className={styles.main}>
                {children}
            </main>
        </>
    )
}

export default Layout
