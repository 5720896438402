import React, { useCallback } from 'react'
import TransitionLink from 'gatsby-plugin-transition-link'

// Taken from https://github.com/koniecznytomek/gatsby-transition-links

export const FadeLink = ({ children, to }) => {
  const exitTransition = {
    length: 0.5,
    zIndex: 20,
    trigger: ({ node }) => {
      exitTransition.exitTrigger(node)
      if (node) {
          node.style.top = -window.pageYOffset + 'px'
      }
      window.scrollTo({ top: -window.pageYOffset })
    },
    exitTrigger: useCallback(container => {
      container.setAttribute('style', 'animation: fadeOut 0.8s ease forwards;')
    }, []),
  }

  const entryTransition = {
    zIndex: 10,
    trigger: ({ node }) => {
      entryTransition.entryTrigger(node)
    },
    entryTrigger: useCallback(container => {
      container.setAttribute('style', 'animation: fadeIn 0.8s ease forwards;')
    }, []),
    delay: 0.3,
    length: 0.7,
  }

  return (
    <>
      <TransitionLink to={to} exit={exitTransition} entry={entryTransition}>
        {children}
      </TransitionLink>
    </>
  )
}

export default FadeLink
