import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import FadeLink from "./fade-link"
import * as styles from "./header.module.scss"

const Layout = () => {
    return (
        <header className={styles.navbar}>
            <div className={styles.logoContainer}>
                <Link key="home" to="/">
                    <StaticImage
                        src="../images/burton-peake.png"
                        alt="Burton Peake"
                        placeholder="tracedSVG"
                        loading="eager"
                        height={36}
                    />
                </Link>
            </div>
            <nav role="navigation" className={styles.nav}>
                <FadeLink to="/about">About</FadeLink>
                <FadeLink to="/team">Team</FadeLink>
                <FadeLink to="/contact">Contact</FadeLink>
            </nav>
        </header>
    )
}

export default Layout
